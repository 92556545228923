import { useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import { ConsentContext } from './ConsentContext';

const useAnalytics = () => {
  const location = useLocation();
  const { analyticsConsent } = useContext(ConsentContext);

  useEffect(() => {
    if (analyticsConsent) {
      ReactGA.initialize("G-TD594PJ1B9");
      ReactGA.send({
        hitType: "pageview",
        page: location.pathname,
        title: document.title,
      });
    }
  }, [location, analyticsConsent]);
};

export default useAnalytics;