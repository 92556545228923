import React, { useContext } from "react";
import "./OrderBanner.css";
import { LanguageContext } from './LanguageContext';

const OrderBanner = () => {
  const { translations } = useContext(LanguageContext);
  const t = translations;

  const steps = [
    {
      id: 1,
      title: t.steps.step1.title, // Use dynamic translation for the title
      description: t.steps.step1.description,
      image: <img src="/order.jpeg" alt={t.alt1} className="boximage" />, // Use alt text dynamically if needed
    },
    {
      id: 2,
      title: t.steps.step2.title,
      description: t.steps.step2.description,
      image: <img src="/envelope.jpeg" alt={t.alt2} className="boximage" />,
    },
    {
      id: 3,
      title: t.steps.step3.title,
      description: t.steps.step3.description,
      image: <img src="/craft-order.jpeg" alt={t.alt3} className="boximage" />,
    },
    {
      id: 4,
      title: t.steps.step4.title,
      description: t.steps.step4.description,
      image: <img src="/received.jpeg" alt={t.alt4} className="boximage" />,
    },
  ];

  return (
    <div className="order-banner-container">
      <h2 className="order-banner-title">{t.processProduct}</h2>
      <div className="order-steps">
        {steps.map((step) => (
          <div key={step.id} className="order-step">
            {step.image}
            <h3 className="step-title">{step.title}</h3>
            <p className="step-description">{step.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OrderBanner;