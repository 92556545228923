import React, { useContext, useState, useRef } from "react";
import "./Home.css";
import { MdOutlineDraw, MdSettingsSystemDaydream } from "react-icons/md";
import { IoBookOutline } from "react-icons/io5";
import { Divider } from '@fluentui/react-components';
import { LanguageContext } from './LanguageContext';
import Header from "./Header";
import AnnouncementBanner from "./AnnouncementBanner";
import Footer from "./Footer";
import HowItWorks from './HowItWorks';
import EmailSignUp from './EmailSignUp';
import WaitlistBanner from './WaitlistBanner';
import SimpleImageUploader from "./SimpleImageUploader";
import { useNavigate } from 'react-router-dom';

import FAQ from './FAQ';
import OurStory from "./OurStory";

const Home = () => {
  const { translations } = useContext(LanguageContext);
  const t = translations;
  const [clickedIcon, setClickedIcon] = useState("pen");
  const [hoveredIcon, setHoveredIcon] = useState(null);

  const howItWorksRef = useRef(null);
  const penRef = useRef(null);
  const uploadRef = useRef(null);
  const bookRef = useRef(null);
  const navigate = useNavigate();

  const handleIconClick = (iconName) => {
    setClickedIcon(iconName);
  };

  const handleIconHover = (iconName) => {
    setHoveredIcon(iconName);
  };

  const handleIconLeave = () => {
    setHoveredIcon(null);
  };

  return (
    <div>
      <AnnouncementBanner message={t.announcement_banner} />
      <Header />
      <div className="home-container">
        <div className="home-header">
          <div>
            <div className="wavy-line-container">
              <svg
                width="100vw"
                height="100"
                viewBox="0 0 1000 100"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0,50 C266.66,0 166.66,100 433.33,50 C800,0 500,100 666.66,50 C833.33,0 833.33,100 1000,50"
                  fill="none"
                  stroke="blue"
                  strokeWidth="2"
                  strokeDasharray="1,5"
                  strokeLinecap="round"
                />
              </svg>
            </div>
            <div className="class-title">
            <h1>{t.title}</h1>
            </div>
          </div>
          <p className="subtitle-text">{t.subtitle}</p>
          <HowItWorks />
        </div>
        <div className="howitworks" ref={howItWorksRef}>
          ✨ {t.howItWorks} ✨
        </div>
        <div className="icons">
          <div
            id="icon-pen"
            ref={penRef}
            className={`icon-item ${
              clickedIcon === "pen" ? "clicked" : ""
            } ${hoveredIcon === "pen" ? "hovered" : ""}`}
            onClick={() => handleIconClick("pen")}
            onMouseEnter={() => handleIconHover("pen")}
            onMouseLeave={handleIconLeave}
          >
            <MdOutlineDraw className="icon-home pen-icon" />
            <div className="text-icon">{t.step1.title}</div>
          </div>
          <div
            id="icon-upload"
            ref={uploadRef}
            className={`icon-item ${
              clickedIcon === "upload" ? "clicked" : ""
            } ${hoveredIcon === "upload" ? "hovered" : ""}`}
            onClick={() => handleIconClick("upload")}
            onMouseEnter={() => handleIconHover("upload")}
            onMouseLeave={handleIconLeave}
          >
            <MdSettingsSystemDaydream className="icon-home upload-icon" />
            <div className="text-icon">{t.step2.title}</div>
          </div>
          <div
            id="icon-book"
            ref={bookRef}
            className={`icon-item ${
              clickedIcon === "book" ? "clicked" : ""
            } ${hoveredIcon === "book" ? "hovered" : ""}`}
            onClick={() => handleIconClick("book")}
            onMouseEnter={() => handleIconHover("book")}
            onMouseLeave={handleIconLeave}
          >
            <IoBookOutline className="icon-home book-icon" />
            <div className="text-icon">{t.step3.title}</div>
          </div>
        </div>

        <div className="explanation-text">
          {clickedIcon === "pen" && <p>{t.step1.description}</p>}
          {clickedIcon === "upload" && <p>{t.step2.description}</p>}
          {clickedIcon === "book" && <p>{t.step3.description}</p>}
        </div>
        <Divider className="divider-footer-home" />
        <div className="howitworks">
          <div className="aligned">{t.tryitout}</div>
          <img src="/woman-experimenting.jpg" alt="kid drawing" className="boximage-how-it-works" />
        </div>
        <p>{t.onlyThreeTries}</p>
        <SimpleImageUploader />
        <div className="order-now" onClick={() => navigate('gallery')}>
        <div>{t.orderNow}</div>
        </div>
        <OurStory />
        <WaitlistBanner />
        <EmailSignUp />
        <FAQ />
      </div>
      <Footer />
    </div>
  );
};

export default Home;