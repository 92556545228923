// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './Components/Home';
import Gallery from './Components/Gallery';
import PrivacyPolicyEn from './Components/PrivacyPolicyEn';
import PrivacyPolicyFr from './Components/PrivacyPolicyFr';
import NotFound from './Components/NotFound';
import { AuthProvider } from './Components/AuthContext';
import { LanguageProvider } from './Components/LanguageContext';
import AnalyticsListener from './Components/AnalyticsListener';
import { ConsentProvider } from './Components/ConsentContext'; 
import ConsentBanner from './Components/ConsentBanner';

function App() {
  return (
    <AuthProvider>
      <LanguageProvider>
        <ConsentProvider>
          <Router>
            <AnalyticsListener />
            <div className="App">
              <ConsentBanner /> 
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="gallery" element={<Gallery />} />
                <Route path="privacy-en" element={<PrivacyPolicyEn />} />
                <Route path="privacy-fr" element={<PrivacyPolicyFr />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </div>
          </Router>
        </ConsentProvider>
      </LanguageProvider>
    </AuthProvider>
  );
}

export default App;