import React, { useContext, useState, useEffect } from 'react';
import { ConsentContext } from './ConsentContext';
import './Consent.css';
import { LanguageContext } from './LanguageContext';

const ConsentBanner = () => {
  const { setAnalyticsConsent } = useContext(ConsentContext);
  const { translations } = useContext(LanguageContext);
  const [shouldRender, setShouldRender] = useState(false); // Controls rendering
  const [animationClass, setAnimationClass] = useState(''); // Controls animation classes
  const t = translations;

  // Delay rendering by 20 seconds and trigger slide-in animation
  useEffect(() => {
    const renderTimer = setTimeout(() => {
      setShouldRender(true);
      setAnimationClass('slide-in');
    }, 20000); // 20 seconds

    return () => clearTimeout(renderTimer); // Cleanup on unmount
  }, []);

  const handleAccept = () => {
    setAnalyticsConsent(true);
    localStorage.setItem('analyticsConsent', 'true');
    triggerSlideOut();
  };

  const handleDecline = () => {
    setAnalyticsConsent(false);
    localStorage.setItem('analyticsConsent', 'false');
    triggerSlideOut();
  };

  const triggerSlideOut = () => {
    setAnimationClass('slide-out');
    setTimeout(() => {
      setShouldRender(false);
    }, 500);
  };

  // Prevent rendering if consent is already set or not yet triggered
  if (!shouldRender) {
    return null;
  }

  return (
    <div className={`consent-banner ${animationClass}`}>
  <img src="/cookies.png" alt="cookie" className="cookie-image" />
  <div className="consent-content">
    <div className="spacing-policy">{t.weusecookies}</div>
    <div className="spacing-policy">
      {t.byclickingaccept}
      <a href="/privacy-en">{t.privacypolicy}</a>.
    </div>
    <div className="consent-buttons">
      <button onClick={handleAccept} className="accept-btn">{t.accept}</button>
      <button onClick={handleDecline} className="decline-btn">{t.decline}</button>
    </div>
  </div>
</div>
  );
};

export default ConsentBanner;