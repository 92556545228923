import React, { useEffect, useContext, useRef } from 'react';
import Header from "./Header";
import OrderBanner from "./OrderBanner";
import Footer from "./Footer";
import { LanguageContext } from './LanguageContext';
import "./Gallery.css";

let shopifyUIInitialized = false; // Global flag
const Gallery = () => {
  const didRun = useRef(false); // Track if effect has run
  const { translations } = useContext(LanguageContext);
  const t = translations;

  useEffect(() => {
    // Prevent double run in Strict Mode development
    if (didRun.current) {
      return;
    }
    didRun.current = true;

    // If already initialized (e.g., user navigated back), skip
    if (shopifyUIInitialized) {
      return;
    }

    if (window.ShopifyBuy && window.ShopifyBuy.UI) {
      ShopifyBuyInit();
    } else {
      loadScript();
    }

    function loadScript() {
      if (document.getElementById('shopify-buy-script')) {
        return;
      }

      const script = document.createElement('script');
      script.id = 'shopify-buy-script';
      script.async = true;
      script.src =
        'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';
      script.onload = ShopifyBuyInit;
      document.body.appendChild(script);
    }

    function ShopifyBuyInit() {
      if (shopifyUIInitialized) return;

      if (!window.ShopifyBuy || !window.ShopifyBuy.buildClient) {
        console.error('ShopifyBuy SDK is not loaded or available.');
        return;
      }

      const client = window.ShopifyBuy.buildClient({
        domain: 'drawnmagic.myshopify.com',
        storefrontAccessToken: '7a7109f0e4dbbd61a37297daf0c42780'
      });

      const node = document.getElementById('collection-component-1732188249938');
      if (!node) {
        console.error('Target node for collection component is missing.');
        return;
      }

      // Clear any existing content before creating the component again
      node.innerHTML = '';

      window.ShopifyBuy.UI.onReady(client).then((ui) => {
        ui.createComponent('collection', {
          id: '524968919304',
          node: node,
          moneyFormat: '%E2%82%AC%7B%7Bamount_with_comma_separator%7D%7D',

          options: {
            product: {
              styles: {
                product: {
                  '@media (min-width: 601px)': {
                    'max-width': 'calc(25% - 20px)',
                    'margin-left': '20px',
                    'margin-bottom': '50px',
                    width: 'calc(25% - 20px)',
                  },
                  img: {
                    height: 'calc(100% - 15px)',
                    position: 'absolute',
                    left: '0',
                    right: '0',
                    top: '0',
                  },
                  imgWrapper: {
                    'padding-top': 'calc(75% + 15px)',
                    position: 'relative',
                    height: '0',
                  },
                },
                button: {
                  'font-weight': 'bold',
                  'color': 'black',
                  'font-size': '16px',
                  'padding-top': '16px',
                  'padding-bottom': '16px',
                  ':hover': {
                    'background-color': '#ecc102',
                  },
                  'background-color': '#ffd200',
                  ':focus': {
                    'background-color': '#ecc102',
                  },
                  'border-radius': '21px',
                },
                quantityInput: {
                  'font-size': '16px',
                  'padding-top': '16px',
                  'padding-bottom': '16px',
                },
              },
              text: {
                button: 'Add to cart',
              },
            },
            productSet: {
              styles: {
                products: {
                  '@media (min-width: 601px)': {
                    'margin-left': '-20px',
                  },
                },
              },
            },
            modalProduct: {
              contents: {
                img: false,
                imgWithCarousel: true,
                button: false,
                buttonWithQuantity: true,
              },
              styles: {
                product: {
                  '@media (min-width: 601px)': {
                    'max-width': '100%',
                    'margin-left': '0px',
                    'margin-bottom': '0px',
                  },
                },
                button: {
                  'font-weight': 'bold',
                  'font-size': '16px',
                  'padding-top': '16px',
                  'padding-bottom': '16px',
                  ':hover': {
                    'background-color': '#285479',
                  },
                  'background-color': '#2c5d86',
                  ':focus': {
                    'background-color': '#285479',
                  },
                  'border-radius': '21px',
                },
                quantityInput: {
                  'font-size': '16px',
                  'padding-top': '16px',
                  'padding-bottom': '16px',
                },
              },
              text: {
                button: 'Add to cart',
              },
            },
            cart: {
              styles: {
                button: {
                  'font-weight': 'bold',
                  'font-size': '16px',
                  'padding-top': '16px',
                  'padding-bottom': '16px',
                  ':hover': {
                    'background-color': '#285479',
                  },
                  'background-color': '#2c5d86',
                  ':focus': {
                    'background-color': '#285479',
                  },
                  'border-radius': '21px',
                },
              },
              text: {
                total: 'Subtotal',
                button: 'Checkout',
              },
            },
            toggle: {
              styles: {
                toggle: {
                  'font-weight': 'bold',
                  'background-color': '#2c5d86',
                  ':hover': {
                    'background-color': '#285479',
                  },
                  ':focus': {
                    'background-color': '#285479',
                  },
                },
                count: {
                  'font-size': '16px',
                },
              },
            },
          },
        });
      });
    }
  }, []);

  return (
    <>
      <Header />
      <OrderBanner />
      <div className="flex-container">
      <img src="/delivery.jpg" alt="Shipping" className="deliveryimage"/>
      <div style={{ padding: '20px', textAlign: 'center', fontSize: '26px', fontWeight: 'bold', color: '#285479' }}>
        {t.shippingestimate}
      </div>
      </div>
      
      <div id="collection-component-1732188249938"></div>
      <Footer />
    </>
  );
};

export default Gallery;