import React, { useContext } from "react";
import { LanguageContext } from './LanguageContext';
import { Link } from "react-router-dom";
import "./NotFound.css";
import Header from "./Header";
const NotFound = () => {
  const { translations } = useContext(LanguageContext);
  const t = translations;

  return (
    <>
    <Header/>
    <div className="not-found-container">
      <h1 className="not-found-title">404</h1>
      <p className="not-found-message">{t.notfound}</p>
      <p className="not-found-subtext">
      {t.notfoundexplaination}
      </p>
      <Link to="/" className="not-found-link">
      {t.takemehome}
      </Link>
    </div>
    </>
  );
};

export default NotFound;