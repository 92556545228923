import React, { useContext } from "react";
import { Link } from 'react-router-dom';
import { FaLinkedin } from 'react-icons/fa';
import './Footer.css';
import { LanguageContext } from './LanguageContext';

const Footer = () => {
  const { translations, language } = useContext(LanguageContext);
  const t = translations;
  const privacyPolicyRoute = language === 'fr' ? '/privacy-fr' : '/privacy-en';

  return (
    <footer className="footer">
      <div className="footer-content">

        {/* Contact Section */}
        <div className="footer-contact">
        <h4><Link to={privacyPolicyRoute}>{t.privacyPolicy}</Link> </h4>
          <h4>{t.contactUs}</h4>
          <div className="contact-email">
          <p><a href="mailto:contact@drawnmagic.com">contact@drawnmagic.com</a></p>
          </div>
        </div>

        {/* Social Media Links */}
        <div className="footer-social">
          <h4>{t.followUs}</h4>
          <div className="social-icons">
            <a href="https://www.linkedin.com/company/drawnmagic" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
              <FaLinkedin />
            </a>
          </div>
        </div>
        
      </div>
      <div className="footer-logo">
          <p className="copyright-text">{t.copyright}</p>
        </div>
    </footer>
  );
};

export default Footer;