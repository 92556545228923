// ConsentContext.js
import React, { createContext, useState } from 'react';

export const ConsentContext = createContext();

export const ConsentProvider = ({ children }) => {
    const [analyticsConsent, setAnalyticsConsent] = useState(() => {
        // Fetch saved consent state from localStorage during initialization
        const savedConsent = localStorage.getItem('analyticsConsent');
        return savedConsent === 'true' ? true : savedConsent === 'false' ? false : null;
    });

    return (
        <ConsentContext.Provider value={{ analyticsConsent, setAnalyticsConsent }}>
            {children}
        </ConsentContext.Provider>
    );
};