import React from 'react';
import Header from "./Header";
import './PrivacyPolicy.css';
import { Link } from 'react-router-dom';
const PrivacyPolicyFr = () => {
  return (
    <>
    <Header />
    <div className="privacy-policy">
    <div>
        
        <h4><Link to="/privacy-en"><span className="fi fi-gb"></span> English</Link> </h4>
        <h4><Link to="/privacy-fr"><span className="fi fi-fr"></span> Français</Link> </h4>
        </div>
        
        <h1 className="privacy-title">Politique de Confidentialité pour DrawnMagic</h1>
      <p className="last-updated">Dernière mise à jour : 14 décembre 2024</p>

      <section>
        <h2 className="section-title">1. Informations que nous collectons</h2>
        <p>
          Nous collectons et traitons les types d'informations personnelles suivants :
        </p>
        <ul>
          <li><b>Détails personnels :</b> Nom, adresse e-mail, adresse de livraison et adresse de facturation.</li>
          <li><b>Informations de paiement :</b> Les détails de paiement sont traités en toute sécurité via Shopify.</li>
          <li><b>Informations de sécurité :</b> Les adresses IP sont collectées à des fins de sécurité et d'audit.</li>
          <li><b>Informations sur les commandes :</b> Détails sur vos commandes, y compris les articles achetés et l'historique des transactions.</li>
        </ul>
        <p>Nous n'utilisons pas de cookies à des fins de suivi ou autres.</p>
      </section>

      <section>
        <h2 className="section-title">2. Comment nous utilisons vos informations</h2>
        <p>Vos informations personnelles sont utilisées aux fins suivantes :</p>
        <ul>
          <li><b>Traitement des commandes :</b> Pour traiter et livrer vos commandes.</li>
          <li><b>Support client :</b> Pour assurer le suivi des tickets de support et résoudre les problèmes.</li>
          <li><b>Communications marketing :</b> Si vous fournissez votre adresse e-mail, nous pouvons envoyer des offres promotionnelles et des mises à jour.</li>
        </ul>
      </section>

      <section>
        <h2 className="section-title">3. Partage de vos informations</h2>
        <p>
          Nous ne partageons vos informations personnelles avec des tiers que si cela est nécessaire pour atteindre les objectifs décrits ci-dessus. Notamment :
        </p>
        <ul>
          <li><b>Shopify :</b> Les informations de paiement et de commande sont traitées en toute sécurité via Shopify.</li>
        </ul>
      </section>

      <section>
        <h2 className="section-title">4. Vos droits</h2>
        <p>En tant qu'utilisateur, vous disposez des droits suivants concernant vos informations personnelles :</p>
        <ul>
          <li><b>Accès :</b> Demander l'accès à vos données.</li>
          <li><b>Correction :</b> Demander des corrections pour toute inexactitude dans vos données.</li>
          <li><b>Suppression :</b> Demander la suppression de vos informations personnelles en nous contactant à <a href="mailto:contact@drawnmagic.com">contact@drawnmagic.com</a>.</li>
        </ul>
      </section>

      <section>
        <h2 className="section-title">5. Sécurité des données</h2>
        <p>
          Nous prenons la protection de vos informations personnelles très au sérieux. Les mesures mises en place incluent :
        </p>
        <ul>
          <li><b>Cryptage :</b> Toutes les données sensibles sont cryptées lors du stockage et de la transmission.</li>
          <li><b>Contrôle d'accès :</b> Les données sont uniquement accessibles au personnel autorisé.</li>
        </ul>
      </section>

      <section>
        <h2 className="section-title">6. Conformité internationale</h2>
        <p>
          Nous opérons en Europe et respectons les réglementations applicables en matière de protection des données, notamment le <b>Règlement Général sur la Protection des Données (RGPD)</b>.
        </p>
      </section>

      <section>
        <h2 className="section-title">7. Mises à jour de la politique</h2>
        <p>
          Nous pouvons mettre à jour cette politique de confidentialité de temps à autre pour refléter les changements dans nos pratiques ou pour des raisons légales ou réglementaires. En cas de mises à jour significatives, nous vous en informerons par e-mail.
        </p>
      </section>

      <section>
        <h2 className="section-title">8. Contactez-nous</h2>
        <p>
          Pour toute question, préoccupation ou pour exercer vos droits relatifs aux données, veuillez nous contacter à :
        </p>
        <p><b>Email :</b> <a href="mailto:contact@drawnmagic.com">contact@drawnmagic.com</a></p>
      </section>
    </div>
    </>
  );
};

export default PrivacyPolicyFr;