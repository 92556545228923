import React, { useState } from 'react';
import './AnnouncementBanner.css';

const AnnouncementBanner = ({ 
  message, 
  backgroundColor = '#0078d4', 
  textColor = '#ffffff', 
  closeColor = '#ffffff' 
}) => {
  const [visible, setVisible] = useState(true);

  
  const handleClose = () => {
    setVisible(false);

  };

  if (!visible) return null;

  return (
    <div className="announcement-banner" style={{ backgroundColor }}>
      <span className="announcement-message" style={{ color: textColor }}>
        {message}
      </span>
      <button
        className="announcement-close"
        style={{ color: closeColor }}
        onClick={handleClose}
        aria-label="Close banner"
      >
        ×
      </button>
    </div>
  );
};


export default AnnouncementBanner;