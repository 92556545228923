import React from 'react';
import Header from "./Header";
import './PrivacyPolicy.css';
import { Link } from 'react-router-dom';
const PrivacyPolicyEn = () => {
  return (
    <>
    <Header />
    <div className="privacy-policy">
    <div>
        
        <h4><Link to="/privacy-en"><span className="fi fi-gb"></span> English</Link> </h4>
        <h4><Link to="/privacy-fr"><span className="fi fi-fr"></span> Français</Link> </h4>
        </div>
        
      <h1 className="privacy-title">Privacy Policy for DrawnMagic</h1>
      <p className="last-updated">Last Updated: December 14th 2024</p>

      <section>
        <h2 className="section-title">1. Information We Collect</h2>
        <p>
          We collect and process the following types of personal information:
        </p>
        <ul>
          <li><b>Personal Details:</b> Name, email address, shipping address, and billing address.</li>
          <li><b>Payment Information:</b> Payment details are processed securely via Shopify.</li>
          <li><b>Security Information:</b> IP addresses are collected for security and audit purposes.</li>
          <li><b>Order Information:</b> Details about your orders, including items purchased and transaction history.</li>
        </ul>
        <p>We do not use cookies for tracking or any other purposes.</p>
      </section>

      <section>
        <h2 className="section-title">2. How We Use Your Information</h2>
        <p>Your personal information is used for the following purposes:</p>
        <ul>
          <li><b>Order Processing:</b> To process and deliver your orders.</li>
          <li><b>Customer Support:</b> To follow up on support tickets and resolve issues.</li>
          <li><b>Marketing Communications:</b> If you provide your email address, we may send promotional offers and updates.</li>
        </ul>
      </section>

      <section>
        <h2 className="section-title">3. Sharing Your Information</h2>
        <p>
          We do not share your personal information with third parties except as necessary to fulfill the purposes outlined above. Specifically:
        </p>
        <ul>
          <li><b>Shopify:</b> Payment and order information is securely processed via Shopify.</li>
        </ul>
      </section>

      <section>
        <h2 className="section-title">4. Your Rights</h2>
        <p>As a user, you have the following rights regarding your personal information:</p>
        <ul>
          <li><b>Access:</b> Request access to your data.</li>
          <li><b>Correction:</b> Request corrections to any inaccuracies in your data.</li>
          <li><b>Deletion:</b> Request the deletion of your personal information by contacting us at <a href="mailto:contact@drawnmagic.com">contact@drawnmagic.com</a>.</li>
        </ul>
      </section>

      <section>
        <h2 className="section-title">5. Data Security</h2>
        <p>
          We take the protection of your personal information seriously. Measures in place include:
        </p>
        <ul>
          <li><b>Encryption:</b> All sensitive data is encrypted during storage and transmission.</li>
          <li><b>Access Control:</b> Data is only accessible by authorized personnel.</li>
        </ul>
      </section>

      <section>
        <h2 className="section-title">6. International Compliance</h2>
        <p>
          We operate in Europe and comply with applicable data protection regulations, including the <b>General Data Protection Regulation (GDPR)</b>.
        </p>
      </section>

      <section>
        <h2 className="section-title">7. Policy Updates</h2>
        <p>
          We may update this Privacy Policy from time to time to reflect changes in our practices or for legal or regulatory reasons. If significant updates are made, we will notify you via email.
        </p>
      </section>

      <section>
        <h2 className="section-title">8. Contact Us</h2>
        <p>
          For any questions, concerns, or to exercise your data rights, please contact us at:
        </p>
        <p><b>Email:</b> <a href="mailto:contact@drawnmagic.com">contact@drawnmagic.com</a></p>
      </section>
    </div>
    </>
  );
};

export default PrivacyPolicyEn;